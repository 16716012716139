import { useEffect, useState } from 'react';

// material-ui
import { 
  Typography,
  Box
} from '@mui/material';


import Marquee from 'react-fast-marquee';
import Logo from './Logo';

import Popover from '@mui/material/Popover';
import Fade from '@mui/material/Fade';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CircularProgress from '@mui/material/CircularProgress';

const NewsMarquee = ({style = {}}) => {
  const [ marqueeText, setMarqueeText] = useState('');
  const [ previewUrl, setPreviewUrl] = useState(null);
  const [ anchorEl, setAnchorEl ] = useState(null);
  const [ open, setOpen ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  useEffect(() => {
    loadNews();
  }, []);

  const handleClick = (event) => {
    const url = event.currentTarget.getAttribute('to');
    setLoading(true);
    setPreviewUrl(url);
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const handleClose = () => {
    setPreviewUrl(null);
    setAnchorEl(null);
    setOpen((previousOpen) => !previousOpen);
  };

      const loadNews = () => {
                  window.fetch('https://shockya.com/news/feed/')
                .then(response => response.text())
                .then(str => {
                        return new window.DOMParser().parseFromString(str, "text/xml")
                })
                .then(data => {
                        var xmlContent = null;
                        var xmlChannel = null;
                  var mText = '';
                  var mContent = [];

                        // get the 'root' node
                        for (var a = 0; a < data.childNodes.length; a++) {
                            if (data.childNodes[a].nodeName == 'rss') {
                                xmlContent = data.childNodes[a];
                            }
                        }
                        // find the channel node
                        for (var i = 0; i < xmlContent.childNodes.length; i++) {
                          if (xmlContent.childNodes[i].nodeName == 'channel') {
                            xmlChannel = xmlContent.childNodes[i];
                          }
                        }
                        // for each item create a link and add the article title as the link text
                        for (var x = 0; x < xmlChannel.childNodes.length; x++) {
                          if (xmlChannel.childNodes[x].nodeName == 'item') {
                            var xmlItems = xmlChannel.childNodes[x];
                            var isFilmon = true; //dwafalse;
                            for(i = 0; i < xmlItems.childNodes.length; i++) {
                                var it = xmlItems.childNodes[i];
                                if(it.nodeName == 'category' && it.lastChild.nodeValue && it.lastChild.nodeValue.toLowerCase().match(/filmon/i)) {
                                        isFilmon = true;
                                        break;
                                }
                            }

                            if(isFilmon) {
                                var title, link = false;
                                for (var y = 0; y < xmlItems.childNodes.length; y++) {
                                      if (xmlItems.childNodes[y].nodeName == 'title') {
                                        title = xmlItems.childNodes[y].lastChild.nodeValue;
                                      }
                                      else if (xmlItems.childNodes[y].nodeName == 'link') {
                                        link = xmlItems.childNodes[y].lastChild.nodeValue;
                                      }
                                      if ((title !== false && title != '') && link !== false) {
                                 mText = (
                                    <a // eslint-disable-line jsx-a11y/no-static-element-interactions
                                      to={`${link}#content`}
                                      onClick={(e) => handleClick(e)} onKeyPress={(e) => handleClick(e)} 
                                    >
                                       <Typography variant="h1" color="white" sx={{p:2, fontFamily: "Antonio, monospace"}}>
                                         {title.toUpperCase()}
                                         <Logo style={{height: '30px', width: 'auto', marginLeft: '20px', marginRight: '20px', borderRadius: '50%', background: 'white', padding: '5px'}} />
                                       </Typography>
                                       </a>
                                 );
                                    mContent.push(mText);
                                        title = false;
                                        link = false;
                                      }
                                    }
                                }
                           }
                        }
                  setMarqueeText(mContent);
            })
            .catch(e => console.error(e));
      }

  return (<>
    <Marquee style={{
      background: '#de3131',
        ...style}}
      pauseOnHover={true}
      
    >
      {Array.isArray(marqueeText) ? 
        marqueeText.map((e) => e)
        : marqueeText
      }
    </Marquee>
      <Popover open={open} anchorEl={anchorEl} transitionComponent={Fade} onClose={handleClose}>

      <Box sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center',
      border: 1,
      bgcolor: 'background.paper',
      borderRadius: '5px',
      position: 'relative'
      }}>
        <iframe src={previewUrl} title="News preview" style={{
            width: '480px',
            maxWidth: '100%',
            aspectRatio: '1',
      }} 
        onLoad={() => setLoading(false)}
        />
        <a href={previewUrl} target="_blank" rel="noreferrer"
          style={{
            position: 'absolute',
            right: 0,
            top: 0,
            width: '32px',
            height: '32px',
            zIndex: 101
          }}
        >
        <OpenInNewIcon />
        </a>
        {loading && (
            <Box
              sx={{
                display: 'flex',
                position: 'absolute',
                alignItems: 'center',
                justifyContent: 'center',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: 'rgba(26,26,26,0.3)',
                zIndex: 100,
              }}
              open={loading}
            >
              <CircularProgress />
            </Box>
        )}
      </Box>
      </Popover>        
  </>);
};

export default NewsMarquee;
