import React from 'react';
import { Container, Typography, Box, Button, Link } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useNavigation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Header from '../components/header';
import Footer from '../components/footer';

const TermsConditions = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  return (<>
  <Header />
    <Container maxWidth="md" sx={{ backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : theme.palette.background.paper }}>
      <Box sx={{ py: 8 }}>
        <Button
          component={Link}
          onClick={() => navigate(-1)}
          startIcon={<ArrowBackIcon />}
          sx={{ mb: 2 }}
        >
          Back 
        </Button>
        <Typography variant="h2" gutterBottom>
          Terms and Conditions
        </Typography>
        <Typography variant="body1" paragraph>
          Last updated: {new Date().toLocaleDateString()}
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
          1. Acceptance of Terms
        </Typography>
        <Typography variant="body1" paragraph>
          By accessing and using this website, you accept and agree to be bound by the terms and provision of this agreement.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
          2. Use License
        </Typography>
        <Typography variant="body1" paragraph>
          Permission is granted to temporarily download one copy of the materials (information or software) on SwissX's website for personal, non-commercial transitory viewing only.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
          3. Disclaimer
        </Typography>
        <Typography variant="body1" paragraph>
          The materials on SwissX's website are provided on an 'as is' basis. SwissX makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
          4. Limitations
        </Typography>
        <Typography variant="body1" paragraph>
          In no event shall SwissX or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on SwissX's website.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
          5. Revisions and Errata
        </Typography>
        <Typography variant="body1" paragraph>
          The materials appearing on SwissX's website could include technical, typographical, or photographic errors. SwissX does not warrant that any of the materials on its website are accurate, complete or current.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
          6. Governing Law
        </Typography>
        <Typography variant="body1" paragraph>
          These terms and conditions are governed by and construed in accordance with the laws of Antigua and Barbuda and you irrevocably submit to the exclusive jurisdiction of the courts in that location.
        </Typography>
      </Box>
    </Container>
    <Footer />
    </>
  );
};

export default TermsConditions; 