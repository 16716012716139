import logo from '../assets/images/logo.png';
//import goldlogo from 'assets/images/goldlogo.png';
//import greenlogo from 'assets/images/greenlogo.png';
//import logoDark from 'assets/images/logo.png';

// ==============================|| LOGO SVG ||============================== //

export const Logo = ({style}) => {
//  const theme = useTheme();

  return (
	<img src={logo} alt="SwissX" style={style ? style : {width: '70px', height: 'auto'}} />
  );
};

/*export const GoldLogo = ({style}) => {
//  const theme = useTheme();

  return (
	<img src={goldlogo} alt="SwissX" style={style ? style : {width: '70px', height: 'auto'}} />
  );
};

export const GreenLogo = ({style}) => {
//  const theme = useTheme();

  return (
	<img src={greenlogo} alt="SB100" style={style ? style : {width: '70px', height: 'auto'}} />
  );
};
*/

export default Logo;
