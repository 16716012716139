import React from 'react';
import { Container, Typography, Box, Button, Link } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useNavigation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Header from '../components/header';
import Footer from '../components/footer';

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  return (<>
  <Header />
    <Container maxWidth="md" sx={{ backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : theme.palette.background.paper }}>
      <Box sx={{ py: 8 }}>
        <Button 
          component={Link}
          onClick={() => navigate(-1)}
          startIcon={<ArrowBackIcon />}
          sx={{ mb: 2 }}
        >
          Back 
        </Button>
        <Typography variant="h2" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          Last updated: {new Date().toLocaleDateString()}
        </Typography>
        
        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
          1. Information We Collect
        </Typography>
        <Typography variant="body1" paragraph>
          We collect information that you provide directly to us, including when you create an account, make a purchase, or contact us for support.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
          2. How We Use Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We use the information we collect to provide, maintain, and improve our services, to process your transactions, and to communicate with you.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
          3. Information Sharing
        </Typography>
        <Typography variant="body1" paragraph>
          We do not sell or rent your personal information to third parties. We may share your information with service providers who assist us in operating our business.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
          4. Security
        </Typography>
        <Typography variant="body1" paragraph>
          We implement appropriate technical and organizational measures to protect your personal information against unauthorized access, alteration, disclosure, or destruction.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
          5. Your Rights
        </Typography>
        <Typography variant="body1" paragraph>
          You have the right to access, correct, or delete your personal information. You may also object to or restrict certain processing of your information.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
          6. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about this Privacy Policy, please contact us at privacy@swissx.com
        </Typography>
      </Box>
    </Container>
    <Footer />
    </>
  );
};

export default PrivacyPolicy; 