import { createTheme } from '@mui/material/styles';

export const getTheme = (mode) => createTheme({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          // Light mode
          primary: {
            main: '#de3131',
            light: '#E45A5A',
            dark: '#9B2222',
          },
          secondary: {
            main: '#EEF2F6',
            light: '#FF8E53',
            dark: '#C73967',
          },
          background: {
            default: '#fafafa',
            paper: '#F5F5F5',
          },
          error: {
            main: '#ff0000',
            light: 'rgb(255, 51, 51)',
            dark: 'rgb(178, 0, 0)',
          },
          text: {
            primary: 'rgba(0, 0, 0, 0.87)',
            secondary: 'rgba(0, 0, 0, 0.54)',
          },
        }
      : {
          // Dark mode
          primary: {
            main: '#de3131',
            light: '#e45a5a',
            dark: '#9b2222',
          },
          secondary: {
            main: '#eef2f6',
            light: 'rgb(241, 244, 247)',
            dark: 'rgb(166, 169, 172)',
            contrastText: 'rgba(0, 0, 0, 0.87)',
          },
          background: {
            default: '#303030',
            paper: '#424242',
          },
          error: {
            main: '#ff0000',
            light: 'rgb(255, 51, 51)',
            dark: 'rgb(178, 0, 0)',
          },
          text: {
            primary: '#FFFFFF',
            secondary: 'rgba(255, 255, 255, 0.7)',
            disabled: 'rgba(255, 255, 255, 0.5)',
            hint: 'rgba(255, 255, 255, 0.5)',
          },
        }),
  },
  typography: {
    h1: {
      fontSize: '2.5rem',
      '@media (min-width:600px)': {
        fontSize: '3.5rem',
      },
    },
    h2: {
      fontSize: '2rem',
      '@media (min-width:600px)': {
        fontSize: '3rem',
      },
    },
    h3: {
      fontSize: '1.75rem',
      '@media (min-width:600px)': {
        fontSize: '2.5rem',
      },
    },
    h4: {
      fontSize: '1.5rem',
      '@media (min-width:600px)': {
        fontSize: '2rem',
      },
    },
    h5: {
      fontSize: '1.25rem',
      '@media (min-width:600px)': {
        fontSize: '1.5rem',
      },
    },
    h6: {
      fontSize: '1rem',
      '@media (min-width:600px)': {
        fontSize: '1.25rem',
      },
    },
    body1: {
      fontSize: '1rem',
      '@media (min-width:600px)': {
        fontSize: '1.1rem',
      },
    },
    body2: {
      fontSize: '0.875rem',
      '@media (min-width:600px)': {
        fontSize: '0.95rem',
      },
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: mode === 'light' ? '#fafafa' : '#de3131',
          color: mode === 'light' ? '#de3131' : '#fafafa',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: mode === 'light' ? 'rgba(255, 255, 255, 0.9)' : 'rgba(45, 45, 45, 0.9) !important',
        },
      },
    },
  },
});

