import { AppBar, Toolbar, Box, Button, IconButton, Menu, MenuItem, useTheme } from '@mui/material';
import { Brightness7 as Brightness7Icon, Brightness4 as Brightness4Icon, Menu as MenuIcon, Close as CloseIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import SwissXLogo from '../assets/images/swissx.svg';
import { useState, useContext } from 'react';
import { ColorModeContext } from '../App';

const Header = () => {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setIsMobileMenuOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsMobileMenuOpen(false);
  };

  return (
    <AppBar position="sticky" elevation={0}>
      <Toolbar>
        <Box
        component="img"
        src={SwissXLogo}
        alt="SwissX Logo"
        sx={{ height: 64, textAlign: 'left',
          filter: theme.palette.mode === 'dark' ? 'drop-shadow(0px 0px 7px white)' : 'none'
        }}
    />
    
    {/* Desktop Menu */}
    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end', gap: 2 }}>
        <Button color="inherit" href="/">Home</Button>
        <Button color="inherit" href="https://island.swissx.com" target="_blank">SwissX Island</Button>
        <Button color="inherit" href="https://swissxtv.com" target="_blank">SwissX TV</Button>
        <Button color="inherit" href="#contact">Buy SwissX</Button>
        <Button color="inherit" href="#contact">Contact</Button>
    </Box>

    <IconButton 
        sx={{ ml: 2 }} 
        onClick={colorMode.toggleColorMode} 
        color="inherit"
    >
        {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
    </IconButton>

    {/* Mobile Menu */}
    <Box sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'flex-end', marginLeft: 'auto' }}>
        <Button 
        color="inherit" 
        onClick={handleMenu}
        startIcon={<MenuIcon />}
        >
        Menu
        </Button>
        <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
            '& .MuiMenu-paper': {
            width: '100%',
            height: '100vh',
            top: '0 !important',
            left: '0 !important',
            maxWidth: '100%',
            maxHeight: 'none',
            borderRadius: 0
            }
        }}
        >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
            <IconButton onClick={handleClose} sx={{ color: 'text.primary' }}>
            <CloseIcon />
            </IconButton>
        </Box>
        <MenuItem onClick={handleClose} href="/">Home</MenuItem>
        <MenuItem onClick={handleClose} href="https://island.swissx.com" target="_blank">SwissX Island</MenuItem>
        <MenuItem onClick={handleClose} href="https://swissxtv.com" target="_blank">SwissX TV</MenuItem>
        <MenuItem onClick={handleClose} href="https://swissx.com/buy" target="_blank">Buy SwissX</MenuItem>
        <MenuItem onClick={handleClose} href="#contact">Contact</MenuItem>
        </Menu>
    </Box>
    </Toolbar>
    </AppBar>

  );
};

export default Header;
