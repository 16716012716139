import React, { useEffect, useContext, useState, useRef } from 'react';
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  Button, 
  Menu, 
  MenuItem,
  Container,
  Card,
  CardContent,
  Box,
  TextField,
  Stack,
  useMediaQuery,
  IconButton,
  Avatar,
  CircularProgress
} from '@mui/material';
import { motion, useScroll, useTransform, AnimatePresence, animate } from 'framer-motion';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import Grid2 from '@mui/material/Grid2';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import SwissXLogo from '../assets/images/swissx.svg';
import { useTheme } from '@mui/material/styles';
import { ColorModeContext } from '../App';
import VideoPlayer from '../components/player';
import CountUp from 'react-countup';
import { ResponsiveContainer, LineChart, Line, Tooltip, YAxis } from 'recharts';
import {ecosystem, currency, innovation, sprout, dollar, gain, assets, aiml, research, tax, dna, trust, earth, fuel } from '../components/pathmorphing';
import bitcoin from '../assets/images/bitcoin.svg';
import ethereum from '../assets/images/ethereum.svg';
import xrp from '../assets/images/xrp.svg';
import solana from '../assets/images/solana.svg';
import Link from '@mui/material/Link';
import alki from '../assets/images/alki.jpg';
import gaston from '../assets/images/gaston.jpg';
import olof from '../assets/images/olof.jpg';
import NewsMarquee from '../components/NewsMarquee';
import Footer from '../components/footer';
import Map from '../components/map';
import Header from '../components/header';
import SwissXBG from '../assets/images/swissxbg1.jpeg';

const cryptoCurrencies = [];
cryptoCurrencies['bitcoin'] = bitcoin;
cryptoCurrencies['ethereum'] = ethereum;
cryptoCurrencies['ripple'] = xrp;
cryptoCurrencies['solana'] = solana;

const playlists = [
    {
        src: 'https://media.swissx.com/dflt4/index.m3u8#t=0,10',
        poster: 'https://media.swissx.com/dflt4/poster.jpg'
    },
    {
        src: 'https://media.swissx.com/gaston/index.m3u8#t=0,10',
    },
    {
        src: 'https://media.swissx.com/vid10/index.m3u8',
    },
    {
        src: 'https://media.swissx.com/newra1/index.m3u8',
    },
    {
        src: 'https://media.swissx.com/vid3/index.m3u8',
    },
    {
        src: 'https://media.swissx.com/lndng1/index.m3u8',
    },
    {
        src: 'https://media.swissx.com/vid2/index.m3u8',
    },
    {
        src: 'https://media.swissx.com/ibm1/index.m3u8',
    },
    {
        src: 'https://media.swissx.com/vid7/index.m3u8',
    }
];

const whyChooseUs = [
  {
    icon: fuel,
    title: 'Sustainable, High-Performance Fuel',
    description: 'Produced from sargassum seaweed and genetically engineered microbes, SWISSX B100 is a clean, renewable alternative to fossil fuels.',
  },
  {
    icon: sprout,
    title: '93% Lower Emissions',
    description: '93% lower emissions, aligning with global carbon reduction goals.',
  },
  {
    icon: dollar,
    title: 'Carbon Credit Rewards',
    description: 'Every 90 gallons purchased earns 1 carbon credit, linked to the EU Carbon Markets, adding further value to the investment.',
  },
  {
    icon: tax,
    title: 'Regulatory and Incentive Benefits',
    description: 'In the United States: Participants in the Cool Fuel Distributor Program can earn up to $7 per gallon in State and Federal rebates, maximizing profitability.',
  },
  {
    icon: dollar,
    title: 'Cost-Effective Pricing',
    description: 'Base production cost: $4.00 per gallon FOB Antigua. Negotiable pricing available for bulk buyers, offering tailored solutions for larger contracts.',
  },
  {
    icon: fuel,
    title: 'Strategic Bunkering in Antigua',
    description: 'Free on Board (FOB) operations located in Antigua, servicing global maritime and aviation clients.',
  },
  

]

const whyInvest = [
  {
    icon: innovation    ,
    title: 'Proven innovation',
    description: 'Pioneering renewable energy solutions with transparent, audited operations',
  },
  {
    icon: research,
    title: 'Research-driven',
    description: 'Investing in cutting-edge research and development to drive progress',
  },
  {
    icon: ecosystem,
    title: 'Global Impact',
    description: 'Supporting sustainability while providing attractive financial returns.',
  },
  {
    icon: ecosystem,
    title: 'Comprehensive Ecosystem',
    description: 'Seamless integration of biofuel production, trading, and carbon credit rewards.',
  },
  {
    icon: trust,
    title: 'Transparent Operations',
    description: 'Rigorous auditing and reporting to ensure accountability and trust.',
  },
  {
    icon: assets,
    title: 'Backed with the SwissX Humanitarian Hedge Fund',
    description: 'A dedicated fund for humanitarian causes, ensuring long-term sustainability and impact.',
  },
  
  
  
]



const teamMembers = [
  {
    name: 'Alki David',
    image: alki,
    description: 'CEO, SwissX, Farmers Antigua Trust'
  },
  {
    name: 'Gaston Browne',
    image: gaston,
    description: 'Board Advisor, Prime Minister of Antigua and Barbuda'
  },
  {
    name: 'Olof Ollson',
    image: olof,
    description: 'MS, PhD, Fujara Genetics'
  }
]

const headers = [
  {
    title: 'SwissX B100',
    subtitle: 'Revolutionizing Clean Energy',
    description: 'Clean energy solutions that save the planet, earn carbon credits, and offer unmatched transparency.',
//    image: 'https://w.swissx.com/swissxbg1.jpeg'
  },
  {
    title: 'SwissX Island',
    subtitle: 'Theme parks and Luxury Resorts',
    description: 'A new destination for the whole family, with a focus on sustainability and innovation.',
//    image: 'https://w.swissx.com/swissxbg1.jpeg'
  },
  {
    title: 'SwissX DNA Labs',
    subtitle: 'Genetic research and development',
    description: 'Developing genetically engineered microbes for biofuel production, species cloning, and more.',
//    image: 'https://w.swissx.com/swissxbg1.jpeg'
  },
  {
    title: 'SwissX AI and data solutions',
    subtitle: 'AI and data solutions for the future',
    description: 'Developing AI and data solutions for the future, including blockchain, AI, and more.',
//    image: 'https://w.swissx.com/swissxbg1.jpeg'
  }
]

const LandingPage = () => {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { scrollY } = useScroll();
  const downMd = useMediaQuery('(max-width: 900px)');
  const viewportHeight = theme.viewportHeight;
  const documentHeight = document.documentElement.scrollHeight;
  const scrollYProgress = useScroll().scrollYProgress;
  const heroY = useTransform(scrollY, [0, downMd ? 500 : 800], [0, downMd ? 500 : 800]);
  const [cryptoData, setCryptoData] = useState({});
  const [header, setHeader] = useState(0);
  const ref = useRef(null);

  const boxVariants = {
    offscreen: { y: 50, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.5 }
    }
  };

  useEffect(() => {
    animate(ref?.current, { translateY: ['20%', 0, 0, 0, '-10%', '-20%'], opacity: [0, 1, 1, 1, 1, 0] }, { duration: 5, ease: 'easeInOut'})
    .then(() => {
            console.log('Animation ended');
            setHeader(header >= headers.length - 1 ? 0 : header + 1);
    });
  }, [header]);

  useEffect(() => {
    const fetchCryptoData = async () => {
      try {
        const data = await fetch(
          'https://api.coingecko.com/api/v3/simple/price?ids=bitcoin,ethereum,ripple,solana&vs_currencies=usd&include_24hr_change=true'
        )
        .then(response => {
            return response.json()
        })
        .catch(error => {
          console.error('Error fetching crypto data:', error);
          return null;
        });
        
        const historicalDataPromises = Object.keys(data).map(async (crypto) => {
          const historyResponse = await fetch(
            `https://api.coingecko.com/api/v3/coins/${crypto}/market_chart?vs_currency=usd&days=2`
          );
          const historyData = await historyResponse.json();
          return [crypto, historyData.prices.map(([timestamp, price]) => ({ timestamp, price }))];
        });

        const historicalData = Object.fromEntries(await Promise.all(historicalDataPromises));

        setCryptoData(
          Object.entries(data).reduce((acc, [crypto, values]) => ({
            ...acc,
            [crypto]: {
              price: values.usd,
              change: values.usd_24h_change,
              history: historicalData[crypto]
            }
          }), {})
        );
      } catch (error) {
        console.error('Error fetching crypto data:', error);
      }
    };

    fetchCryptoData();
    const interval = setInterval(fetchCryptoData, 300000); // Update every minute

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {/* Sticky Header */}
      <Header />

      {/* Hero Section with Parallax */}
      <motion.div style={{ y: heroY }}>
        <Box
          sx={{
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            background: `url(${SwissXBG}) center center / cover no-repeat`,
            backgroundColor: theme.palette.mode === 'dark' ? theme.palette.text.primary : undefined,
            backgroundBlendMode: theme.palette.mode === 'dark' ? 'difference' : 'normal',
          }}
        >
            <motion.div ref={ref} >
                <Typography variant="h1" align="center" sx={{ fontWeight: 'bold', textShadow: `0 0 10px ${theme.palette.primary.main}` }}>{headers[header].title}</Typography>
                <Typography variant="h2" align="center" sx={{ fontWeight: 'bold', textShadow: `0 0 10px ${theme.palette.secondary.main}` }}>{headers[header].subtitle}</Typography>
                <Typography variant="h3" align="center" sx={{ textShadow: `0 0 10px ${theme.palette.secondary.main}` }}>{headers[header].description}</Typography>
            </motion.div>
          <Box sx={{ mt: 4 }}>
            <Typography variant="h2" color="primary" align="center" sx={{ fontWeight: 'bold', mb: 1 }}>
              <CountUp
                start={0}
                end={14257361200}
                duration={2.5}
                separator=","
                useEasing={true}
                useGrouping={true}
                decimals={0}
                prefix="$"
              />
            </Typography>
            <Typography variant="h4" color="primary" align="center">
              SwissX Humanitarian Hedge Fund
            </Typography>
          </Box>
          <Box sx={{position: 'absolute', bottom: 0, left: 0, right:0,zIndex: 2}}>
              <NewsMarquee />
          </Box>
        </Box>
      </motion.div>

      {/* Video Section */}
      <Container>
        <Box sx={{ height: downMd ? '60vh' : '100vh', width: '100%', position: 'relative' }}>
            <VideoPlayer playlists={playlists} 
            ref={(el) => {
                if (!el) return;
                const observer = new IntersectionObserver(
                ([entry]) => {
                    if (entry.isIntersecting) {
                    el.play()
                    ?.then(_ => {
                        console.log('Video played');
                    })
                    ?.catch(err => {
                        console.error('Error playing video', err);
                    });
                    } else {
                    el.pause()
                    ?.then(_ => {
                        console.log('Video paused');
                    })
                    ?.catch(err => {
                        console.error('Error pausing video', err);
                    });
                    }
                },
                { threshold: 0.5 }
                );
                observer.observe(el);
            }}
            />
        </Box>
      </Container>
      {/* Key Features Section */}
      <Container sx={{ p: 3, backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : theme.palette.background.paper }}>
        <Box sx={{ my: 8 }}>
          <Typography variant="h3" align="center" gutterBottom>
            Key Features of SWISSX B100
          </Typography>
          
          <Box sx={{ mb: 4 }}>
            <Typography variant="h5" color="primary" gutterBottom>
              Sustainable, High-Performance Fuel
            </Typography>
            <Typography variant="body1" paragraph>
              Produced from sargassum seaweed and genetically engineered microbes, SWISSX B100 is a clean, renewable alternative to fossil fuels.
            </Typography>
            <Typography variant="body1" paragraph>
              93% lower emissions, aligning with global carbon reduction goals.
            </Typography>
            <Typography variant="body1" paragraph>
              Emits a pleasant French fry aroma, improving the experience compared to traditional diesel.
            </Typography>
          </Box>
          
          <Box sx={{ mb: 4 }}>
            <Typography variant="h5" color="primary" gutterBottom>
              Strategic Bunkering in Antigua
            </Typography>
            <Typography variant="body1" paragraph>
              Free on Board (FOB) operations located in Antigua, servicing global maritime and aviation clients.
            </Typography>
            <Typography variant="body1" paragraph>
              Positioned as a renewable energy hub for the Caribbean, supporting the region's transition to sustainable fuels.
            </Typography>
          </Box>
          
          <Box sx={{ mb: 4 }}>
            <Typography variant="h5" color="primary" gutterBottom>
              Cost-Efficient and Flexible Pricing
            </Typography>
            <Typography variant="body1" paragraph>
              Base production cost: $4.00 per gallon FOB Antigua.
            </Typography>
            <Typography variant="body1" paragraph>
              Negotiable pricing available for bulk buyers, offering tailored solutions for larger contracts.
            </Typography>
            <Typography variant="body1" paragraph>
              Carbon credits can lower energy costs by up to 2%, providing additional savings.
            </Typography>
          </Box>
          
          <Box sx={{ mb: 4 }}>
            <Typography variant="h5" color="primary" gutterBottom>
              Regulatory and Incentive Benefits
            </Typography>
            <Typography variant="body1" paragraph>
              In the United States: Participants in the Cool Fuel Distributor Program can earn up to $7 per gallon in State and Federal rebates, maximizing profitability.
            </Typography>
            <Typography variant="body1" paragraph>
              In Europe: Biofuel mandates are driving energy users toward renewable fuels like SWISSX B100, ensuring growing demand.
            </Typography>
          </Box>
          
          <Box sx={{ mb: 4 }}>
            <Typography variant="h5" color="primary" gutterBottom>
              Carbon Credit Rewards
            </Typography>
            <Typography variant="body1" paragraph>
              Every 90 gallons purchased earns 1 carbon credit, linked to the EU Carbon Markets, adding further value to the investment.
            </Typography>
          </Box>
        </Box>
      </Container>

      {/* Why Choose Section */}
      <Container>
        <Box sx={{ my: 8 }}>
          <Typography variant="h2" align="center" gutterBottom>
            Why Choose SwissX 
          </Typography>
          <Grid2 container spacing={3}>
            {whyChooseUs.map((item) => (
              <Grid2 size={{ sm: 12, md: 4}} key={item.title}>
                <motion.div
                  initial="offscreen"
                  whileInView="onscreen"
                  viewport={{ once: true, amount: 0.3 }}
                  variants={boxVariants}
                  style={{
                    height: '100%',
                  }}
                >
                  <Card sx={{ height: '100%' }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', mb: 2 }}>
                        <Typography variant="h5">
                            <svg width="96" height="96" viewBox="0 0 32 32">
                                <g> 
                                    <path fill={theme.palette.primary.main} d={item.icon} />
                                </g>
                            </svg>
                        </Typography>
                        <Typography variant="h5" sx={{ p: 2 , m: 2}}>{item.title}</Typography>
                        <Typography variant="body1">{item.description}</Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </motion.div>
              </Grid2>
            ))}
          </Grid2>
        </Box>
      </Container>
      {/* Crypto Rates Section */}
      <Container>
        <Box sx={{ my: 8 }}>
          <Typography variant="h2" align="center" gutterBottom>
            Cryptocurrency Market Trends
          </Typography>
          <Typography variant="body1" align="center" paragraph>
            Real-time rates of major cryptocurrencies in the market.
          </Typography>
          
          <Grid2 container spacing={3}>
            {cryptoData && Object.keys(cryptoData).map((name) => (
              <Grid2 size={{ xs: 12, md: 6 }} key={name}>
                <motion.div
                  initial="offscreen"
                  whileInView="onscreen"
                  viewport={{ once: true, amount: 0.3 }}
                  variants={boxVariants}
                  style={{
                    height: '100%',
                  }}
                >
                <Card sx={{ height: '100%', position: 'relative', overflow: 'visible' }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <Box
                          component="img"
                          src={cryptoCurrencies[name]}
                          alt={name}
                          sx={{ width: 40, height: 40, mr: 2, borderRadius: '50%' }}
                        />
                        <Typography variant="h5" sx={{ textTransform: 'capitalize' }}>
                          {name}
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                        <Typography variant="body2" color="text.secondary">Current Price:</Typography>
                        <Typography variant="body1" fontWeight="bold">
                          {cryptoData?.[name]?.price ? `$${cryptoData[name].price.toLocaleString()}` : 'Loading...'}
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                        <Typography variant="body2" color="text.secondary">24h Change:</Typography>
                        <Typography 
                          variant="body1" 
                          color={cryptoData?.[name]?.change >= 0 ? 'success.main' : 'error.main'}
                        >
                          {cryptoData?.[name]?.change ? `${cryptoData[name].change > 0 ? '+' : ''}${cryptoData[name].change.toFixed(2)}%` : '-'}
                        </Typography>
                      </Box>
                      <Box sx={{ height: 80, mt: 2, mb: 1 }}>
                        {cryptoData?.[name]?.history ? (
                          <ResponsiveContainer width="100%" height="100%">
                            <LineChart data={cryptoData[name].history}>
                              <YAxis type="number" domain={['dataMin', 'dataMax']} hide={true} />
                              <Line 
                                type="monotone" 
                                dataKey="price" 
                                stroke={cryptoData[name].change >= 0 ? theme.palette.success.main : theme.palette.error.main} 
                                strokeWidth={2}
                                dot={false}
                              />
                              <Tooltip 
                                contentStyle={{ 
                                  backgroundColor: theme.palette.background.paper,
                                  border: 'none',
                                  borderRadius: 4,
                                  boxShadow: theme.shadows[3]
                                }} 
                                formatter={(value) => [`$${value.toFixed(2)}`, 'Price']}
                                labelFormatter={() => ''}
                              />
                            </LineChart>
                          </ResponsiveContainer>
                        ) : (
                          <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <CircularProgress size={24} />
                          </Box>
                        )}
                      </Box>
                    </CardContent>
                  </Card>
                </motion.div>
              </Grid2>
            ))}
          </Grid2>
        </Box>
      </Container>


      {/* Why Invest Section */}
      <Container>
        <Box sx={{ my: 8 }}>
          <Typography variant="h2" align="center" gutterBottom>
            Why Invest
          </Typography>
          <Grid2 container spacing={3}>
            {whyInvest.map((item) => (
              <Grid2 size={{ xs: 12, md: 4}} key={item.title}>
                <motion.div
                  initial="offscreen"
                  whileInView="onscreen"
                  viewport={{ once: true, amount: 0.3 }}
                  variants={boxVariants}    
                  style={{
                    height: '100%',
                  }}
                >
                  <Card sx={{ height: '100%' }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', mb: 2 }}>
                        <Typography variant="h5">
                            <svg width="96" height="96" viewBox="0 0 32 32">
                                <g> 
                                    <path fill={theme.palette.primary.main} d={item.icon} />
                                </g>
                            </svg>
                        </Typography>
                        <Typography variant="h5" sx={{ p: 2 , m: 2}}>{item.title}</Typography>
                        <Typography variant="body1">{item.description}</Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </motion.div>
              </Grid2>
            ))}
          </Grid2>
        </Box>
      </Container>

      {/* Team Section */}
      <Container>
        <Box sx={{ my: 8, justifyContent: 'center', display: 'flex',  flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant="h2" align="center" gutterBottom>
            Our Team
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 2 }}>
            {teamMembers.map((member) => (
                <Card>
                  <CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', mb: 2, width: '200px' }}>
                      <Avatar src={member.image} sx={{ width: 100, height: 100 }} />
                      <Typography variant="h5">{member.name}</Typography>
                      <Typography variant="body1">{member.description}</Typography>
                    </Box>
                  </CardContent>
                </Card>
            ))}
          </Box>
        </Box>
      </Container>

      {/* Contact Form Section with Map Background */}
      <Map />

      {/* Footer */}
      <Footer />
    </>
  );
};

export default LandingPage;


