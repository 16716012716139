import React, { useEffect, useRef } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import 'videojs-playlist';
//import 'videojs-contrib-quality-levels';
//import 'videojs-http-source-selector';

const VideoPlayer = ({ playlists, autoplay = false, controls = true, fluid = true }) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      
      if (!videoElement) return;

      playerRef.current = videojs(videoElement, {
        autoplay,
        controls,
        fluid,
        responsive: true,
        html5: {
          hls: {
            enableLowInitialPlaylist: true,
            smoothQualityChange: true,
            overrideNative: true
          }
        }
      }, () => {
        console.log('Player is ready');
      });

      // Initialize the quality selector plugin
      //playerRef.current.httpSourceSelector();

      // Setup the playlist
      if (playlists && playlists.length > 0) {
        const formattedPlaylists = playlists.map(item => ({
          sources: [{
            src: item.src,
            type: 'application/x-mpegURL'
          }],
          poster: item.poster || '',
          title: item.title || ''
        }));

        playerRef.current.playlist(formattedPlaylists);
        
        // Add playlist UI if there are multiple videos
        if (formattedPlaylists.length > 1) {
          playerRef.current?.playlist.autoadvance(0);
          
          // Add event listener for playlist item change
          playerRef.current?.on('playlistitem', (event, { index }) => {
            console.log(`Playing playlist item ${index}`);
          });
        }
      }
    }

    // Cleanup function
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, [playlists, autoplay, controls, fluid]);

  return (
    <div data-vjs-player>
      <video 
        ref={videoRef} 
        className="video-js vjs-big-play-centered"
      />
    </div>
  );
};

// Example usage:
// const playlists = [
//   { 
//     src: 'https://example.com/video1.m3u8',
//     poster: 'https://example.com/poster1.jpg',
//     title: 'Video 1'
//   },
//   { 
//     src: 'https://example.com/video2.m3u8',
//     poster: 'https://example.com/poster2.jpg',
//     title: 'Video 2'
//   }
// ];
// <VideoPlayer playlists={playlists} />

export default VideoPlayer;
