import { useTheme } from '@mui/material/styles';
import { Box, Container, Typography, Grid2, Link } from '@mui/material';
import Logos1 from '../assets/images/logos1.png';
import Logos2 from '../assets/images/logos2.png';
import { Facebook, X, Instagram } from '@mui/icons-material';

const Footer = () => {
  const theme = useTheme();

  return (  
    <Box
    component="footer"
    sx={{
    py: 3,
    px: 2,
    mt: 'auto',
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
    }}
    >
        <Container maxWidth="lg">
            <Grid2 container spacing={3}>
                <Grid2 xs={12} sm={4}>
                    <Typography variant="h6" color="text.primary" gutterBottom>
                        About SwissX
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        SwissX is revolutionizing clean energy with sustainable, high-performance biofuel solutions.
                    </Typography>
                </Grid2>
                <Grid2 xs={12} sm={4}>
                </Grid2>
                <Grid2 xs={12} sm={4}>
                    <Typography variant="h6" color="text.primary" gutterBottom>
                        Legal
                    </Typography>
                    <Link href="/privacy-policy" color="inherit" sx={{ display: 'block', mb: 1 }}>
                        Privacy Policy
                    </Link>
                    <Link href="/terms-conditions" color="inherit" sx={{ display: 'block' }}>
                        Terms & Conditions
                    </Link>
                </Grid2>
                <Grid2 xs={12} sm={6}>
                        <Box component="img" src={Logos1} alt="Partners" sx={{ 
                            height: 'auto', textAlign: 'left' ,
                            width: '50%',
                            filter: theme.palette.mode === 'dark' ? 'saturate(1.2) drop-shadow(0px 0px 7px white)' : 'none'
                        }} />
                        <Box component="img" src={Logos2} alt="Partners" sx={{ height: 'auto', textAlign: 'left',
                            width: '50%',
                            filter: theme.palette.mode === 'dark' ? 'saturate(1.2) drop-shadow(0px 0px 7px white)' : 'none'
                        }} />
                </Grid2>
                <Grid2 xs={12} sm={6}>
                    <Typography variant="h6" color="text.primary" gutterBottom>
                        Follow us
                    </Typography>
                    <Link href="https://www.facebook.com/profile.php?id=100033431475061" target="_blank" color="inherit" sx={{ display: 'block', mb: 1 }}>
                        <Facebook />
                    </Link>
                    <Link href="https://www.x.com/alkidavid" target="_blank" color="inherit" sx={{ display: 'block', mb: 1 }}>
                        <X />
                    </Link>
                    <Link href="https://www.instagram.com/swissxcbd" target="_blank" color="inherit" sx={{ display: 'block', mb: 1 }}>
                        <Instagram />
                    </Link>
                </Grid2>
            </Grid2>
            <Typography variant="body2" color="text.secondary" align="center" sx={{ pt: 4 }}>
                © {new Date().getFullYear()} SwissX. All rights reserved.
            </Typography>
        </Container>
    </Box>
  );
};

export default Footer;
