import { useTheme } from '@mui/material/styles';
import { motion, AnimatePresence } from 'framer-motion';
import { Stack, TextField, Button, Typography, Box } from '@mui/material';
import React from 'react';  

const ContactForm = () => {
    const [formData, setFormData] = React.useState({
      name: '',
      email: '',
      phone: '',
      message: ''
    });
    const [isSubmitted, setIsSubmitted] = React.useState(false);
  
    const theme = useTheme();
    
    const handleChange = (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      fetch('https://w.swissx.com/api/contact', {
        method: 'POST',
        body: JSON.stringify(formData), 
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setIsSubmitted(true);
      })
      .catch(error => {
        console.error('Error:', error);
      });
    };
  
    return (
      <AnimatePresence mode="wait">
        {!isSubmitted ? (
          <motion.form 
            key="form"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onSubmit={handleSubmit}
          >
            <Stack spacing={3}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
              <TextField
                fullWidth
                label="Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <TextField
                fullWidth
                label="Phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
              />
              <TextField
                fullWidth
                label="Message"
                name="message"
                multiline
                rows={4}
                value={formData.message}
                onChange={handleChange}
                required
              />
              <Button 
                variant="contained" 
                type="submit"
                sx={{
                  background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.light} 90%)`,
                  color: theme.palette.primary.contrastText
                }}
              >
                Send Message
              </Button>
            </Stack>
          </motion.form>
        ) : (
          <motion.div
            key="success"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            style={{
              textAlign: 'center',
              padding: '2rem'
            }}
          >
            <Typography variant="h4" component="h2" gutterBottom>
              Thank you for contacting us!
            </Typography>
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ delay: 0.2, type: "spring" }}
            >
              <Box
                component="span"
                sx={{
                  display: 'inline-block',
                  fontSize: '4rem',
                  color: theme.palette.success.main
                }}
              >
                ✓
              </Box>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    );
  };
  
  export default ContactForm;
  